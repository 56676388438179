<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="userCollection"
        :search="search"
        :sort-by="['christian_name1']"
        @click:row="handleClickItem"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-spacer></v-spacer> -->
            <UserModalForm
              :loader="loader"
              :modalData="modalData"
              :userGroups="userGroupCollection"
              :roles="roleCollection"
              :statuses="statuses"
              :permissions="permissions"
              :messages="messages"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></UserModalForm>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.system.user[item.status].value
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/settings/users/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.id != 1"
            small
            @click.prevent="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th style="max-width: 100px">
                <v-select
                  v-model="typeFilter"
                  :items="['Select', ...typeCollection]"
                  item-text="salesPersonName"
                  item-value="salesPersonName"
                ></v-select>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApiService from "@/core/services/api.service";
import UserModalForm from "./UserModalForm";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_USER,
  USER_COLLECTION,
} from "@/core/services/store/user.module.js";
import {
  FETCH_USER_GROUPS,
  USER_GROUP_COLLECTION,
} from "@/core/services/store/userGroup.module.js";
import {
  FETCH_ROLE,
  ROLE_COLLECTION,
} from "@/core/services/store/role.module.js";

const initialFormData = {
  status: 1,
  christian_name1: "",
  christian_name2: "",
  surname: "",
  password: "",
  locale: i18nService.getActiveLanguage(),
  newsletter: 0,
  email: "",
  user_groups: [],
  roles: [],
};
export default {
  name: "Users",
  components: { UserModalForm },
  data() {
    return {
      loader: false,
      appLocale: i18nService.getActiveLanguage(),
      search: "",
      permissions: [],
      headers: [
        {
          text: "First name",
          value: "christian_name1",
        },
        {
          text: "Last name",
          value: "surname",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Type",
          value: "type",
          filter: (value) => {
            if (this.typeFilter == "Select") return true;
            return value == this.typeFilter;
          },
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        languages: i18nService.languages,
        selectedLocale: i18nService.languages[0],
        editedIndex: -1,
        editedItem: Object.assign({}, initialFormData),
      },
      dialogDelete: false,
      typeFilter: "Select",
      messages: [],
    };
  },

  computed: {
    ...mapGetters([
      USER_COLLECTION,
      USER_GROUP_COLLECTION,
      ROLE_COLLECTION,
      "statuses",
    ]),
    typeCollection() {
      return this.userCollection.map((item) => {
        return item.type;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([FETCH_USER, FETCH_USER_GROUPS, FETCH_ROLE]),
    // ...mapActions([FETCH_USER, FETCH_USER_GROUPS, FETCH_ROLE]),
    editItem(id) {
      ApiService.get("user/" + id)
        .then(({ data }) => {
          data.user_groups.map((item) => {
            item.groupName = item.translations[this.appLocale].name;
          });
          this.modalData.editedItem = Object.assign({}, data);
          // console.log(data.user_groups);
          // console.log(this.modalData.editedItem.user_groups);
          this.modalData.editedIndex = data.id;
          if (!data.status) {
            data.status = 0;
          }

          // data.user_groups = data.user_groups.map((item) => {
          //   item.name = item.translations.en.name;
          //   return item;
          // });

          // this.modalData.editedItem = Object.assign({}, data);

          this.modalData.dialog = true;
          this.modalData.appLocale = this.appLocale;
          // this.modalData.userGroups = this[USER_GROUP_COLLECTION];
          // this.modalData.roles = this[ROLE_COLLECTION];
          if (this.modalData.editedItem.locale) {
            this.modalData.selectedLocale = this.modalData.editedItem.locale;
          } else {
            this.modalData.selectedLocale = this.appLocale;
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this[USER_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete("user/" + this.editedItem.id)
        .then(() => {
          this.fetchUser();
          this.fetchUserGroups();
          this.fetchRole();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      setTimeout(() => {
        this.modalData.editedItem = Object.assign({}, initialFormData);
      }, 50);
      this.$router.push({ name: "users" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, initialFormData);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      this.loader = true;
      if (this.modalData.editedIndex > -1) {
        model.status = parseInt(model.status);
        model.user_groups = model.user_groups.map(function (item) {
          return item.id;
        });
        model.roles = model.roles.map(function (item) {
          return item.id;
        });
        ApiService.put("user/" + model.id, model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUser();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            // this.warningAlert.text = error.message;
            // this.warningAlert.show = true;
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors) {
                  this.setError(field, errors[field][0]);
                }
              }
            }
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        //create model
        model.status = parseInt(model.status);
        model.user_groups = model.user_groups.map(function (item) {
          return item.id;
        });
        model.roles = model.roles.map(function (item) {
          return item.id;
        });
        ApiService.post("user", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUser();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors) {
                  this.setError(field, errors[field][0]);
                }
              }
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/settings/users/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.user." + action);
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("user") > -1
        );
      }
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "users" }]);
    this.fetchUser();
    this.fetchUserGroups();
    this.fetchRole();
    this.setPermissions();
  },
};
</script>
